:root {
  --bg-color: rgb(247, 255, 251);
  --text-color: rgb(181, 186, 192);
  --marginSize: 2vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headblock {
	width: 100%;
	height: 40vh;    
	display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8vh;
  font-family: 'Roboto';
  color: var(--text-color);
  position: fixed;
  z-index: -1;
	opacity: 1;
}