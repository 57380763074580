

.pod {
    width: 100%;
    margin-top: var(--marginSize);
}

.column {
    /* computers */
  @media only screen and (min-width: 801px) {
        width: 31%;
        float: left;
        padding: calc(var(--marginSize)/2);
        background-color: var(--bg-color);
    }
    /* phones+tablets */
    @media only screen and (max-width: 800px) {
        width: 90%;
        float: left;
        padding-left: 5%;
        background-color: var(--bg-color);
    }
}

.grid {
    width: 100%;
    position: relative;
    padding-top: 40vh;
}

img {
    max-width:100%;
    max-height:100%;
    width: 100%;
    height: auto;
}